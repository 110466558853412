@charset "UTF-8";
/*! main
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-25 07:08:10
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2024-03-21 18:49:23
*/
/* @SEE import the font in _custom.scss */
/*
* @Author: Jeff Shields
* @Date:   2020-01-25 07:08:34
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2024-01-05 12:44:56
*/
/*! bs5.3 required
*/
/*
* @Author: Jeff Shields
* @Date:   2019-01-20 06:51:37
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-07-06 10:48:40
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-25 07:14:06
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-11-24 11:28:57
*/
.navbar-toggler-right {
  position: relative;
}

.navbar-toggler-right[title]:hover::before {
  content: attr(title);
  position: absolute;
  top: 25%;
  left: -100px;
}

/*
* @Author: Jeff Shields
* @Date:   2022-01-27 15:00:18
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-11-25 12:04:12
*/
body {
  background-color: var(--bs-body-bg);
}

.container-fluid {
  max-width: 1600px;
}

.page {
  display: block;
  display: flex;
  flex-direction: column;
  background-color: var(--bs-body-bg);
  min-height: 100vh;
  height: 100%;
  padding-left: 2px;
  padding-right: 2px;
}

@supports (display: grid) {
  #header {
    grid-area: hd;
  }
  #primary-nav {
    grid-area: n1;
  }
  #secondary-nav {
    grid-area: n2;
  }
  #main {
    grid-area: mn;
  }
  #sidebar1 {
    grid-area: s1;
  }
  #sidebar2 {
    grid-area: s2;
  }
  #ads {
    grid-area: ad;
  }
  #footer {
    grid-area: ft;
  }
  .page {
    display: grid;
    grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn mn mn mn mn mn mn" "s1 s1 s1 s1 s1 s1 s1 s1 s1 s1 s1 s1" "ad ad ad ad ad ad ad ad ad ad ad ad" "ft ft ft ft ft ft ft ft ft ft ft ft";
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: minmax(min-content, auto);
    column-gap: var(--bs-gap, 1.5rem);
    row-gap: 0;
  }
  @media (min-width: 992px) {
    .page {
      display: grid;
      grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn mn mn mn mn mn mn" "s1 s1 s1 s1 s1 s1 s1 s1 s1 s1 s1 s1" "ad ad ad ad ad ad ad ad ad ad ad ad" "ft ft ft ft ft ft ft ft ft ft ft ft";
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: minmax(min-content, auto);
      column-gap: var(--bs-gap, 1.5rem);
      row-gap: 0;
      min-height: 100vh;
      height: 100%;
    }
    .grid-1col {
      grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn mn mn mn mn mn mn" "s1 s1 s1 s1 s1 s1 s1 s1 s1 s1 s1 s1" ".. ad ad ad ad ad ad ad ad ad ad .." "ft ft ft ft ft ft ft ft ft ft ft ft";
    }
    .grid-2col {
      grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn mn mn s1 s1 s1 s1" "ft ft ft ft ft ft ft ft ft ft ft ft";
    }
    .grid-2col.ads {
      grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn mn mn s1 s1 s1 s1" ".. mn mn mn mn mn mn mn ad ad ad .." "ft ft ft ft ft ft ft ft ft ft ft ft";
    }
    .grid-2col-flip {
      grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "s1 s1 s1 s1 mn mn mn mn mn mn mn mn" "ft ft ft ft ft ft ft ft ft ft ft ft";
    }
    .grid-3col-633 {
      grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn s1 s1 s1 s2 s2 s2" "ft ft ft ft ft ft ft ft ft ft ft ft";
    }
  }
}
@media (min-width: 1400px) {
  .grid-2col {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "hd hd hd hd hd hd hd hd hd hd hd hd" "mn mn mn mn mn mn mn mn s1 s1 s1 s1" "ft ft ft ft ft ft ft ft ft ft ft ft";
    padding-left: 0;
    padding-right: 0;
  }
}
.sitemap {
  display: block;
  display: flex;
  flex-direction: column;
  background-color: var(--bs-body-bg);
  min-height: 100vh;
  height: 100%;
}

/*! _index
 */
/* load extras
* @Author: Jeff Shields
* @Date:   2019-01-20 07:22:35
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-04-12 08:00:10
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-25 06:55:12
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-04-12 08:09:00
*/
/*
* @Author: Jeff Shields
* @Date:   2023-04-12 07:52:03
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-04-12 08:11:13
*/
:root {
  --yaa-loaded: 1;
  --yaa-maps-width: 98%;
  --yaa-maps-height: 20rem;
  --yaa-maps-height--large: 25rem;
  --yaa-ribbon-border-width: 3px;
}

/*! custom
*/
.navbar-brand {
  max-width: 75%;
}

.rhythum {
  margin-bottom: 1.5rem !important;
}

.picture-frame {
  border: 5px ridge var(--bs-warning);
}

.img-info1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img-info1:before,
.img-info:after {
  content: "";
}

.resizable {
  overflow: auto;
  resize: both;
}

footer a {
  color: inherit;
}

/*! _blockquote
*/
.blockquote-quotemark {
  padding-left: 2em;
}
.blockquote-quotemark:before {
  display: block;
  height: 0;
  content: "“";
  margin-left: -0.5em;
  font: italic 4em/0.75 Cochin, Georgia, "Times New Roman", serif;
  color: var(--bs-info);
}

/*! _browserhappy
*/
.browsehappy {
  margin: 0.2em 0;
  background: var(--bs-light);
  color: var(--bs-dark);
  padding: 60px 1em 0.2em;
  text-align: center;
}

/*! _calendar
 */
.day-of-week {
  width: 14.2857142857%;
}

/*! _cards @OBSOLETE @DEPRECATED: not required with BS5
*/
/*
* @Author: Jeff Shields
* @Date:   2019-01-16 18:44:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2024-01-05 12:46:46
*/
.sidebar {
  /* shorthand: name / type */
  container: sidebar/inline-size;
}

.text-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: 1px solid white;
  border-radius: 0.25rem;
}

.text-overlay:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.text-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: 1px solid white;
  border-radius: 0.25rem;
}

.text-overlay:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@container sidebar (max-width: var(--bs-breakpoint-sm)) {
  .card {
    min-width: 100%;
  }
}
/*
* @Author: Jeff Shields
* @Date:   2022-07-16 09:09:48
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2022-07-16 09:10:23
*/
.membership-form {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

/*! _google-maps
 */
/*
* @Author: Jeff Shields
* @Date:   2018-03-27 15:33:23
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-04-12 07:37:27
*/
.google-maps {
  width: var(--yaa-maps-width);
  height: var(--yaa-maps-height);
  margin: 0 auto 1rem;
  overflow: auto;
  resize: both;
}

.google-maps--large {
  height: var(--yaa-maps-height--large);
}

.google-map iframe {
  height: 100%;
  width: 100%;
}

/*! _images
 */
/*
* @Author: Jeff Shields
* @Date:   2019-03-31 07:40:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2022-04-13 09:21:08
*/
.overlay {
  background-color: #000;
  opacity: 1;
}

.overlay:hover {
  background-color: var(--bs-light);
  opacity: 0.5;
  transition: opacity 0.3s;
}

.image-container {
  position: relative;
  background-color: var(--bs-dark);
}

.image-overlay {
  position: absolute;
  left: 0;
  top: 2px;
  opacity: 1;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1029;
}

/*! _islands
 */
.island, .island-left {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .island {
    max-width: 33%;
    margin-left: 1em;
    float: right;
  }
  .island-left {
    max-width: 33%;
    margin-right: 1em;
    float: left;
  }
  .island-sm {
    max-width: 25%;
  }
  .island-lg {
    max-width: 50%;
  }
}
/*! _lists
 */
/*
* @Author: Jeff Shields
* @Date:   2019-01-29 09:33:13
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2024-04-15 07:40:29
*/
.separator .list-inline-item + .list-inline-item {
  border-left: 1px solid currentColor;
  padding-left: 0.5rem;
}

.list-checkmarks {
  padding-left: 1.5rem;
  list-style: none;
}
.list-checkmarks li {
  padding-left: 1rem;
}
.list-checkmarks li:before {
  color: var(--bs-primary);
  content: "✓";
  margin: 0 1em 0 -2em;
}

.list-checkmarks-success {
  color: inherit;
}
.list-checkmarks-success li::before {
  color: var(--bs-success);
}

.list-checkmarks-warning {
  color: inherit;
}
.list-checkmarks-warning li:before {
  color: var(--bs-warning);
}

.list-checkmarks-danger {
  color: inherit;
}
.list-checkmarks-danger li:before {
  color: var(--bs-danger);
}

.list-checkmarks-info {
  color: inherit;
}
.list-checkmarks-info li:before {
  color: var(--bs-info);
}

.list-checkmarks-x {
  color: inherit;
}
.list-checkmarks-x li:before {
  color: var(--bs-danger);
  content: "X";
  margin: 0 1em 0 -2em;
}

/*! _type
 */
.text-smallcaps {
  font-variant: small-caps;
}

.dropcap::first-letter {
  background-color: transparent;
  color: var(--bs-primary);
  float: left;
  font: normal normal 3.5em/0.8em serif;
  margin: 0 0.083em 0 0;
  padding: 0.0625em 0 0;
  text-shadow: 3px, 2px, 2px, rgba(0, 0, 0, 0.5);
}

.dropcaps::first-letter {
  float: left;
  color: var(--bs-primary);
  font-size: 3em;
  font-weight: 400;
  padding: 10px 10px 10px 5px;
  margin-top: 2px;
}

.dropcaps-bg::first-letter {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  padding-bottom: 12px;
  margin-right: 10px;
  border-radius: 2px;
  padding: 10px 5px;
  font-size: 2.8em;
  margin-top: 4px;
}

.dropcaps-circle::first-letter {
  border: solid 1px var(--bs-primary);
  border-radius: 30px;
  padding: 10px 8px;
  font-size: 2.3em;
  margin-right: 10px;
  margin-top: 2px;
}

.dropcaps-circle-bg::first-letter {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border-radius: 30px;
  padding: 10px 8px;
  font-size: 2.3em;
  margin-right: 10px;
  margin-top: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-wrap: balance;
}

/* sets max-width to 60ch for all p tags in wysiwyg */
.wysiwyg > p,
.wysiwyg li {
  max-width: 60ch;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1200px) {
  /**
   * Sets the font size of all paragraphs in the WYSIWYG area.
   * The font size is clamped between 1rem and 1.25rem, with a minimum and maximum value based on the viewport width.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/clamp
   */
  .wysiwyg > p:not([class*=fs-]):not(.lead):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6),
  .wysiwyg li,
  body {
    /* Set the font size using the `clamp` function */
    font-size: clamp(1rem, 4vw + 1rem, 1.25rem);
  }
  /*
  * sets max-width for main text in a container
  * in large screens
  *
  * @media breakpoint xl
  *
  * @selector card-text
  *
  * @property max-width
  * @property font-size
  * @property margin
  */
  /* sets max-width to 60ch for all main text in large screens */
  main .card-text {
    max-width: 60ch;
    font-size: clamp(1rem, 4vw + 1rem, 1.25rem);
  }
}
/*! _idx-rn
*/
/*
* @Author: Jeff Shields
* @Date:   2019-03-31 07:42:46
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2022-04-13 09:13:58
*/
.loading-rn {
  display: none;
}

#embeded iframe,
.embeded iframe {
  width: 100%;
}

/*! _audio
 */
.audio {
  width: 75%;
  transition: all 0.5s linear;
  box-shadow: 2px 2px 4px 0px var(--bs-gray-300);
  border-radius: 7px 7px 7px 7px;
}

/*! _back-to-top
*/
/*--------------------------------------------------*/
/* Back to Top */
/*--------------------------------------------------*/
.back-top a {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999999;
  color: #eee;
  background-color: rgb(5, 5, 5);
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.25s ease;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.back-top a:hover {
  background-color: rgb(5, 5, 5);
  background-color: rgba(0, 0, 0, 0.7);
}

.back-top a i {
  font-size: 32px;
}

@media (max-width: 767.98px) {
  .back-top a {
    position: fixed;
    bottom: 40px;
    right: 10px;
  }
  .back-top a i {
    font-size: 1.6em;
  }
}
/*! _mobi
 */
/*
* @Author: Jeff Shields
* @Date:   2019-03-27 10:49:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2024-01-06 06:17:14
*/
.nav-logo {
  height: 2.6em;
  margin-left: 1em;
}
@media (min-width: 768px) {
  .nav-logo {
    height: 3em;
    margin-left: 3em;
  }
}
@media (min-width: 992px) {
  .nav-logo {
    height: 3.5em;
    margin-left: 4em;
  }
}

.mbr-section-btn a.btn:not(.btn-form) {
  border-radius: 100px;
}

.mobi .btn {
  padding: 0.4rem 1.5rem;
  display: -webkit-inline-flex;
  align-items: center;
}

.btn-white-outline {
  background: none;
  border-color: var(--bs-white);
  color: var(--bs-dark);
}

.btn-white-outline:active,
.btn-white-outline.active {
  background: var(--bs-white);
  border-color: var(--bs-white);
  color: var(--bs-dark);
}

/*! _ribbon
 */
/*
* @Author: Jeff Shields
* @Date:   2019-01-29 10:24:25
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2024-02-23 08:01:19
*/
.box {
  position: relative;
  border: 0 solid var(--bs-dark);
  background: transparent;
}

/* common */
.ribbon {
  width: 180px;
  height: 180px;
  overflow: hidden;
  position: absolute;
  top: -var(--yaa-ribbon-border-width)2;
  left: -var(--yaa-ribbon-border-width)2;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: var(--yaa-ribbon-border-width) solid var(--bs-success);
}

.ribbon span {
  position: absolute;
  display: block;
  width: 300px;
  padding: 10px 0;
  box-shadow: 0 3px 10px -5px rgb(0, 0, 0);
  color: var(--bs-white);
  font: 700 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  right: -46px;
  top: 59px;
  transform: rotate(-45deg);
}

.ribbon::before {
  top: 0;
  right: 0;
}

.ribbon::after {
  bottom: 0;
  left: 0;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid var(--bs-success);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--bs-success);
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid var(--bs-success);
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--bs-success);
}

.ribbon-success span {
  background: var(--bs-success);
  background: linear-gradient(#115c39 0%, var(--bs-success) 100%);
}

.ribbon-success span::before {
  border-left: var(--yaa-ribbon-border-width) solid var(--bs-success);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-success);
}

.ribbon-success span::after {
  border-right: var(--yaa-ribbon-border-width) solid var(--bs-success);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-success);
}

.ribbon-danger::before,
.ribbon-danger::after {
  border: var(--yaa-ribbon-border-width) solid var(--bs-danger);
}

.ribbon-danger span {
  background: var(--bs-danger);
  background: linear-gradient(#bd2130 0%, var(--bs-danger) 100%);
}

.ribbon-danger span::before {
  border-left: var(--yaa-ribbon-border-width) solid var(--bs-danger);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-danger);
}

.ribbon-danger span::after {
  border-right: var(--yaa-ribbon-border-width) solid var(--bs-danger);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-danger);
}

.ribbon-warning::before,
.ribbon-warning::after {
  border: var(--yaa-ribbon-border-width) solid var(--bs-warning);
}

.ribbon-warning span {
  color: #000;
  background: var(--bs-warning);
  background: linear-gradient(#d39e00 0%, var(--bs-warning) 100%);
}

.ribbon-warning span::before {
  border-left: var(--yaa-ribbon-border-width) solid var(--bs-warning);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-warning);
}

.ribbon-warning span::after {
  border-right: var(--yaa-ribbon-border-width) solid var(--bs-warning);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-warning);
}

.ribbon-info::before,
.ribbon-info::after {
  border: var(--yaa-ribbon-border-width) solid var(--bs-info);
}

.ribbon-info span {
  color: #000;
  background: var(--bs-info);
  background: linear-gradient(#0aa1c0 0%, var(--bs-info) 100%);
}

.ribbon-info span::before {
  border-left: var(--yaa-ribbon-border-width) solid var(--bs-info);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-info);
}

.ribbon-info span::after {
  border-right: var(--yaa-ribbon-border-width) solid var(--bs-info);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-info);
}

.ribbon-light::before,
.ribbon-light::after {
  border: var(--yaa-ribbon-border-width) solid var(--bs-light);
}

.ribbon-light span {
  color: #000;
  background: var(--bs-light);
  background: linear-gradient(#dae0e5 0%, var(--bs-light) 100%);
}

.ribbon-light span::before {
  border-left: var(--yaa-ribbon-border-width) solid var(--bs-light);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-light);
}

.ribbon-light span::after {
  border-right: var(--yaa-ribbon-border-width) solid var(--bs-light);
  border-top: var(--yaa-ribbon-border-width) solid var(--bs-light);
}

/*! _bootnavbar
*/
/*
* @Author: Jeff Shields
* @Date:   2020-01-02 13:30:02
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2023-01-07 09:01:29
*
* @source: https://github.com/kmlpandey77/
*     version 1.1.1
*/
/*
 * mod jas Jan 7, 2022
 *     revert back to using this file to control colour
 *         to match theme by using css variables
 *     force enable to true @see templates->layout->blocks->css.twig
 *         stop using CDN
 */
.dropdown-menu {
  margin-top: 0;
}

.dropdown-menu .dropdown-toggle::after {
  vertical-align: middle;
  border-left: 4px solid;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
}

.dropdown-menu .dropdown .dropdown-menu {
  left: 100%;
  top: 0%;
  margin: 0 20px;
  border-width: 0;
}

.dropdown-menu .dropdown .dropdown-menu.left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li a:hover,
.dropdown-menu > li.show {
  background: var(--bs-primary);
  color: var(--bs-white);
}

.dropdown-menu > li.show > a {
  color: var(--bs-white);
}

@media (min-width: 768px) {
  .dropdown-menu .dropdown .dropdown-menu {
    margin: 0;
    border-width: 1px;
  }
}
/*! _print
 */
@media print {
  body {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
  }
  h1, .h1 {
    font-size: 19.5pt;
  }
  h2, .h2 {
    font-size: 18pt;
  }
  h3, .h3 {
    font-size: 16.5pt;
  }
  h4, .h4 {
    font-size: 15pt;
  }
  h5, .h5 {
    font-size: 13.5pt;
  }
  h6, .h6 {
    font-size: 12pt;
  }
  .lead {
    font-size: 13.5pt;
  }
  #demoSelect,
  #back-top {
    display: none;
  }
  .container {
    width: auto;
  }
  .card-columns {
    column-count: 2;
  }
}

/*# sourceMappingURL=extras.css.map */
